<template>
	<div class="main">
		<h2>Contact Coach</h2>
		<form @submit.prevent="submitRequest">
			<div
				class="form-control"
				:class="{ invalid: !enteredEmail.isValid }"
			>
				<label for="email">Your E-Mail</label>
				<input
					type="email"
					id="email"
					v-model.trim="enteredEmail.val"
				/>
			</div>
			<div
				class="form-control"
				:class="{ invalid: !enteredMessage.isValid }"
			>
				<label for="message">Your Message</label>
				<textarea
					id="message"
					rows="5"
					v-model.trim="enteredMessage.val"
				></textarea>
			</div>
			<p class="errors" v-if="!formIsValid">
				Please enter a valid email or check that you wrote a message.
			</p>
			<div class="actions">
				<base-button mode="outline">Send Message!</base-button>
			</div>
		</form>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				enteredEmail: {
					val: "",
					isValid: true,
				},
				enteredMessage: {
					val: "",
					isValid: true,
				},
				formIsValid: true,
			};
		},
		methods: {
			submitRequest() {
				this.formIsValid = true;
				this.validate();

				if (this.formIsValid) {
					this.$store.dispatch("requests/contactCoach", {
						email: this.enteredEmail.val,
						message: this.enteredMessage.val,
						coachId: this.$route.params.id,
					});
                    this.$router.replace('/coaches');
				}
			},
			validate() {
				if (
					this.enteredEmail.val === "" ||
					!this.enteredEmail.val.includes("@")
				) {
					this.enteredEmail.isValid = false;
					this.formIsValid = false;
				}

				if (this.enteredMessage.val === "") {
					this.enteredMessage.isValid = false;
					this.formIsValid = false;
				}
			},
		},
	};
</script>

<style scoped>
	h2 {
		text-align: center;
	}
	form {
		margin: 1rem;
		border: 1px solid #ccc;
		border-radius: 12px;
		padding: 1rem;
	}

	.form-control {
		margin: 0.5rem 0;
	}

	label {
		font-weight: bold;
		margin-bottom: 0.5rem;
		display: block;
	}

	input,
	textarea {
		display: block;
		width: 100%;
		font: inherit;
		border: 1px solid #ccc;
		padding: 0.15rem;
	}

	input:focus,
	textarea:focus {
		border-color: #3d008d;
		background-color: #faf6ff;
		outline: none;
	}

	.errors {
		font-weight: bold;
		color: red;
	}

	.actions {
		text-align: center;
	}
</style>
